import { useMemo, useState } from "react";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import useFetchActivities, {
  useCreateActivity,
  useUpdateActivity,
} from "../hooks/useActivities";
import { validateActivity } from "../utils/validations";

const DashboardTable = () => {
  const [validationErrors, setValidationErrors] = useState({});

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 40,
      },
      {
        accessorKey: "hour_duration",
        header: "Hour Duration",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.hour_duration,
          helperText: validationErrors?.hour_duration,
        },
      },
      {
        accessorKey: "tag.name",
        id: "tagName",
        header: "Project Tag",
        enableClickToCopy: true,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.tagName,
          helperText: validationErrors?.tagName,
          //remove any previous validation errors when activity focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              tagName: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              description: undefined,
            }),
        },
      },
      {
        accessorKey: "date",
        header: "Date",
        muiEditTextFieldProps: {
          required: true,
          type: "date",
          dateSetting: {
            format: "YYYY/mm/dd",
          },
          error: !!validationErrors?.date,
          helperText: validationErrors?.date,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              date: undefined,
            }),
        },
      },
    ],
    [validationErrors]
  );

  const {
    data: fetchedActivities = [],
    isError: isLoadingActivitiesError,
    isFetching: isFetchingActivities,
    isLoading: isLoadingActivities,
  } = useFetchActivities();
  const { mutateAsync: createActivity, isPending: isCreatingActivity } =
    useCreateActivity();
  const { mutateAsync: updateActivity, isPending: isUpdatingActivity } =
    useUpdateActivity();

  const handleCreateActivity = async ({ values, table }) => {
    const { tagName } = values;
    values.tag = { name: tagName };

    const newValidationErrors = validateActivity(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createActivity(values);
    table.setCreatingRow(null);
  };

  const handleEditActivity = async ({ values, table }) => {
    const { tagName } = values;
    values.tag = { name: tagName };

    const newValidationErrors = validateActivity(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateActivity(values);
    table.setEditingRow(null);
  };

  const handleArchive = async (row) => {
    if (window.confirm("Are you sure you want to delete this activity?")) {
      const payload = row.original;
      payload.is_archived = true;
      const newValidationErrors = validateActivity(payload);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await updateActivity(payload);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedActivities,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableEditing: true,
    enableFullScreenToggle: false,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingActivitiesError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: {
      sx: { minHeight: "500px" },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateActivity,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleEditActivity,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">New Activity</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {internalEditComponents} {}
        </DialogContent>
        <DialogActions sx={{ padding: 4 }}>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Activity</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents} {}
        </DialogContent>
        <DialogActions sx={{ padding: 4 }}>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <IconEdit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleArchive(row)}>
            <IconTrash />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="outlined"
        sx={{
          color: "salmon.main",
          borderColor: "salmon.main",
          margin: 2,
        }}
        onClick={() => {
          table.setCreatingRow(true);
        }}
      >
        Add New Activity
      </Button>
    ),
    state: {
      isLoading: isLoadingActivities,
      isSaving: isCreatingActivity || isUpdatingActivity,
      showAlertBanner: isLoadingActivitiesError,
      showProgressBars: isFetchingActivities,
    },
  });

  return <MaterialReactTable table={table} sx={{ padding: "16px" }} />;
};

const queryClient = new QueryClient();

const DashboardTableProvider = () => (
  <QueryClientProvider client={queryClient}>
    <DashboardTable />
  </QueryClientProvider>
);

export default DashboardTableProvider;
