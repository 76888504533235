import React, { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import {
  CssBaseline,
  createTheme,
  Box,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Navbar from "./components/Navbar";
import Home from "./components/Home";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0, // always fetch fresh copy of data
      cacheTime: Infinity, // keep stored cache until browser refresh
    },
  },
});

function App() {
  let theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  theme = responsiveFontSizes(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() =>
      createTheme(theme, {
        palette: {
          salmon: theme.palette.augmentColor({
            color: {
              primary: "salmon.main",
              main: "#FF5733",
            },
            name: "salmon",
          }),
        },
        typography: {
          fontSize: 16,
          fontFamily: `"Inter", "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", "Liberation Sans", "Arial", "sans-serif"`,
        },
      })
    ),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className="App">
          <Navbar />
          <Box>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/analytics" element={<ViewAnalytics />} /> */}
            </Routes>
          </Box>
        </Box>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
