import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { baseURL } from "../config";

export const fetchActivities = async () => {
  try {
    const response = await fetch(`${baseURL}/api/v1/tracker/activity/`, {
      method: "GET",
    });
    const activities = await response.json();
    return activities.results ?? [];
  } catch (error) {
    console.error(
      `An error occurred. Please try again later: ${error.message}`
    );
  }
};

export default function useFetchActivities() {
  return useQuery({
    queryKey: ["activities"],
    queryFn: async () => {
      return fetchActivities();
    },
    refetchOnWindowFocus: false,
  });
}

export const useCreateActivity = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (activityData) => {
      const response = await fetch(`${baseURL}/api/v1/tracker/activity/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(activityData),
      });
      return response.data;
    },
    onMutate: (newActivityInfo) => {
      queryClient.setQueryData(["activities"], (prevActivities) => [
        ...prevActivities,
        {
          ...newActivityInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["activities"] }),
  });
};

export const useUpdateActivity = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (activityData) => {
      const response = await fetch(
        `${baseURL}/api/v1/tracker/activity/${activityData.id}/`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(activityData),
        }
      );
      return response.data;
    },
    onMutate: (newActivityInfo) => {
      queryClient.setQueryData(["activities"], (prevActivities) => [
        ...prevActivities,
        {
          ...newActivityInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["activities"] }),
  });
};
