import * as React from "react";
import { Box, Toolbar } from "@mui/material";
import Dashboard from "./Dashboard";

function Home() {
  return (
    <Box component="main" sx={{ p: 5 }}>
      <Toolbar />
      <Dashboard />
    </Box>
  );
}

export default Home;
