import { isNaN, isNil } from "lodash";

const isEmptyNilOrNaN = (value) => {
  return isNil(value) || isNaN(value) || value === "";
};

const required = (value, message = "This field is required.") => {
  if (isEmptyNilOrNaN(value)) {
    return message;
  }
  return undefined;
};

export const validateActivity = (activity) => {
  return {
    description: required(activity.description, "Kindly input a description"),
    hour_duration: required(
      activity.hour_duration,
      "Kindly input hour duration"
    ),
    date: required(activity.date, "Kindly input date"),
  };
};
